import React, { FunctionComponent, ReactElement } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { SEO, GeneralJumbotron, SummaryTemplate, CallToAction } from '../components';
import { MainLayout } from '../layouts';
import { ImagingImagesQuery } from '../shared/interfaces';

const ImagingPage: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<ImagingImagesQuery>(graphql`
        query {
            imagingBackgroundImage: file(name: {eq: "imagingBackground"}, extension: {eq: "jpg"}) {
                publicURL
            }
            imagingSummaryImage: file(name: {eq: "imagingSummary"}, extension: {eq: "jpg"}) {
                publicURL
            }
        }
    `);

    return (
        <MainLayout>
            <SEO title="PCH | Imaging"/>
            <GeneralJumbotron
                title="Imaging"
                backgroundImageURL={queryResult?.imagingBackgroundImage.publicURL}
                backgroundText="Imaging"
            />
            <SummaryTemplate image={queryResult?.imagingSummaryImage.publicURL}>
                <p>
                    {`
                        At PCH IPA, we provide a comprehensive radiology solution,
                        with a network of over 360 centers that offer top-of-the-line
                        equipment and access to some of the best neurological and
                        musculoskeletal radiologists. As you know, high-quality reads
                        ensure optimal outcomes. As a member of PCH IPA, you will
                        experience a streamlined approach to scheduling and a quick
                        turnaround for reports. Our state-of-the-art software digitally
                        uploads all of your clients’ imaging and reports, which you can
                        access from anywhere in the world, 24/7.
                    `}
                </p>
                <p>
                    {`For a full list of program offerings, contact us at `}
                    <a href="tel:+18337244111">
                        <FontAwesomeIcon icon={faPhoneAlt}/>
                        {`1-833-PCH-4111`}
                    </a>
                </p>
            </SummaryTemplate>
            {/* <CallToAction/> */}
        </MainLayout>
    );
}

export default ImagingPage;
